import React from 'react';
import image1 from "../assets/images/6UpIqRzw.png"
import image2 from "../assets/images/CSgZgoiY.png"
import image3 from "../assets/images/R0iQgvpU.png"
import image4 from "../assets/images/fzgZJ4Zc.jpeg"
import image5 from "../assets/images/j3clJqO8.jpeg"
import image6 from "../assets/images/wRddNwNQ.png"
import ImageGrid from '../components/ImageGrid';
import PhotoSlider from '../components/PhotoSlider';

const Home = () => {
    const images = [
        // image1,
        // image2,
        // image3,
        image4,
        // image5,
        // image6,
    ];

    return (
        <div className="App home-page hero-slider">
            {/* <ImageGrid images={images} /> */}
            <PhotoSlider images={images} />
        </div>
    );
};

export default Home;
