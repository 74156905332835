import React from 'react'
import { AiOutlineSend } from 'react-icons/ai';
import './Photospage.css'

function Photospage() {
    return (
        <div className='Photos-container'>
            <h2 className='Photos-title'>Photos</h2>
            <ul className='Photos-describe'>
                <li className='Photos-list'>
                    <AiOutlineSend className='Photos-arrow' />
                    <span className='Photos-text'>
                        From time to time, this website may feature photos provided by<span className='Photos-higlight'>Jonathan A. Shayne</span>and<span className='Photos-higlight'>Anthony L. Werthan.</span>These photos are sourced from <a href="https://www.lulu.com/shop/anthony-l-werthan-and-jonathan-a-shayne/werthan-bag-a-history/paperback/product-12v2d4m4.html?q=werthan&page=1&pageSize=4" style={{ color: "#ffcc00" }} target="_blank">“Werthan Bag: A History,”</a> a paperback book published on January 29, 2010.
                    </span>
                </li>
                <li className='Photos-list'>
                    <AiOutlineSend className='Photos-arrow' />
                    <span className='Photos-text'>
                        The Werthan family has generously donated a vast majority, if not all, of their photographs and over 200 artifacts to the Tennessee State Museum, where these items are on display year-round.
                        For additional details on <a href="https://www.lulu.com/shop/anthony-l-werthan-and-jonathan-a-shayne/werthan-bag-a-history/paperback/product-12v2d4m4.html?q=werthan&page=1&pageSize=4" style={{ color: "#ffcc00" }} target="_blank">“Werthan Bag: A History,”</a>.
                    </span>
                </li>
            </ul>
        </div>
    )
}

export default Photospage