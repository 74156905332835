import React from 'react'
import { AiOutlineSend } from 'react-icons/ai';
import "./Legaldisclaimer.css"
import { Link } from 'react-router-dom';

const Legaldisclaimer = () => {
    return (
        <div className='legaldisclaimer-container'>
            <h2 className='legaldisclaimer-title'>Legal Disclaimer</h2>
            <ul className='legaldisclaimer-describe'>
                <span className='legaldisclaimer-title2'><li>Website Usage</li></span>
                <AiOutlineSend /> We strive to ensure that all content and information on the <a href="http://www.werthanartfoundation.org/" style={{ color: "#ffcc00" }} target="_blank">www.werthanartfoundation.org</a> website is accurate and up-to-date. However, inaccuracies may occur, and we make no warranty or representation regarding the completeness, reliability, or accuracy of the information. Neither the Werthan Art Foundation nor any contributing third parties shall be held responsible for any damage or loss resulting from your use of, or reliance on, the information, content, or functionality of this website.


                <span className='legaldisclaimer-title2'><li>Technical Issues</li></span>
                <AiOutlineSend /> We are not liable for any technical malfunctions related to the website, including but not limited to server downtimes, coding issues, or data loss.

                <span className='legaldisclaimer-title2'><li>Privacy and Cookies</li></span>
                <AiOutlineSend /> Details about how we use cookies and handle your personal data can be found in our Privacy Statement.

                <span className='legaldisclaimer-title2'><li>Copyright</li></span>
                <AiOutlineSend /> All content on this website, including but not limited to text, images, logos, and artwork, is protected by copyright laws. Unless expressly permitted by applicable law or authorized by Werthan Art Foundation, any reproduction, modification, or distribution of the content, in whole or in part, is strictly prohibited without prior written consent from the Werthan Art Foundation.

                <span className='legaldisclaimer-title2'><li>E-mail & Web-Form Communications</li></span>
                <AiOutlineSend /> E-mail communications from the Werthan Art Foundation are intended solely for the use of the individual or entity to whom they are addressed and may contain confidential and/or privileged material. Unauthorized review, use, disclosure, or distribution is strictly prohibited. If you have received an e-mail from us in error, please <Link to="/contact"><span style={{ color: "#ffcc00", textDecoration: 'underline' }}>contact us</span></Link> immediately at <a href="mailto:inquiry@werthanartfoundation.org" style={{ color: "#ffcc00" }} target="_blank">inquiry@werthanartfoundation.org</a> and delete the e-mail.

                <span className='legaldisclaimer-title2'><li>Non-Affiliate Entities</li></span>
                <AiOutlineSend />The Werthan Art Foundation is a non-profit organization dedicated to supporting and facilitating the processing of Werthan Art. Once processed, the Werthan Art Foundation licenses the art, in both its tangible and intangible formats, to for-profit entities for the purpose of product creation. A primary licensee of this art is the Werthan Trading Company, which can be found at <a href="https://werthantradingcompany.com/" style={{ color: "#ffcc00" }} target="_blank">werthantradingcompany.com</a> This company specializes in producing tangible products and merchandise. Outside of its licensee obligations, which include direct licensing fee payments to the Werthan Art Foundation for the rights to use Werthan Art, the Werthan Trading Company is neither affiliated with nor a part of the Werthan Art Foundation. Additionally, the Werthan Art Foundation has no ties to Werthan & Company, Werthan Bag & Burlap Company, Werthan-Morgan-Hamilton Bag Company, Werthan Bag Corporation, or Werthan Packaging Inc. It’s worth noting that all these companies are no longer registered and are not in good standing with the state of Tennessee. Moreover, the Werthan Art Foundation maintains no business relationship with any member of the Werthan family. <span style={{ color: "#ffcc00" }}>(Refer to the Non-Affiliated Disclaimer below)</span>


                <span className='legaldisclaimer-title2'><li>Temporary and Educational Nature of the Site</li></span>
                <AiOutlineSend /> This website is currently under construction and is for educational purposes only. We will not be accepting donations until all regulatory paperwork and compliances have been fulfilled.

                By using this website, you acknowledge and agree to this disclaimer and assume the risks related to its use. If you do not agree with this disclaimer, please refrain from using our website.

                <span className='legaldisclaimer-title2'><li>Non-Affiliated Disclaimer</li></span>
                <li className='sublist-disclaimer'>
                    <AiOutlineSend /> The names Werthan & Company, Werthan Bag & Burlap Company, Werthan-Morgan-Hamilton Bag Company, Werthan Bag Corporation, and Werthan Packaging Inc., collectively known as the <span className='legaldisclaimer-higlight'> “Werthan Non-Entities”</span> as described in Section <span className='legaldisclaimer-higlight'>Non-Affiliate Entities</span>, and specifically living descendants of:
                    <ul className='legaldisclaimer-sub-describe'>
                        <li><span className='legaldisclaimer-higlight'>Meier Werthan </span>(born August 24, 1843, in Nashville, Tennessee) and<span className='legaldisclaimer-higlight'>Minnie Leibman</span>(born March 13, 1857, in Nashville, Tennessee),</li>
                        <li><span className='legaldisclaimer-higlight'>Bernard Werthan Jr.</span> (born April 18, 1931, in Nashville, Tennessee) and<span className='legaldisclaimer-higlight'>Betty Jane Kornman</span> (born January 11, 1932, in Nashville),</li>
                        <li><span className='legaldisclaimer-higlight'>May Werthan</span> (born February 19, 1934) and<span className='legaldisclaimer-higlight'>Herbert Shayne</span> (born September 15, 1926),</li>
                    </ul>
                    as well as any other individuals living since 2014 in the<span className='legaldisclaimer-higlight'> United States (98 individuals)</span> or internationally in countries like<span className='legaldisclaimer-higlight'> England (1)</span>, <span className='legaldisclaimer-higlight'> Belgium (1)</span>,<span className='legaldisclaimer-higlight'> Switzerland (2)</span>,<span className='legaldisclaimer-higlight'> Germany (15)</span>,<span className='legaldisclaimer-higlight'> Czech Republic (4)</span>,<span className='legaldisclaimer-higlight'> Austria (18)</span>,<span className='legaldisclaimer-higlight'> Israel (2)</span>,<span className='legaldisclaimer-higlight'> Vietnam (1)</span>, and any other individuals with the surname<span className='legaldisclaimer-higlight'> Werthan</span> not accounted for in 2014, when these records of surnames was produced (collectively referred to as “Werthan Descendants.”)
                </li>
                <li className='sublist-disclaimer'>
                    <AiOutlineSend /> The name <span className='legaldisclaimer-higlight'> “Werthan” </span>has been in the public domain since and even prior to the year 1762, predating the copyright laws of 1989. It has no associated copyrights, trademarks, or intellectual property rights as a standalone name or in connection with the Werthan Non-Entities. Neither the Werthan Non-Entities nor the Werthan Descendants claim any rights over the name <span className='legaldisclaimer-higlight'> “Werthan,” </span> recognizing its public domain status.
                </li>
                <li className='sublist-disclaimer'>
                    <AiOutlineSend /> The Werthan Art Foundation is registered under common law, with notice to the public, its existence publicly noted by this website and filings with the United States Government as of September 2023. It is crucial to emphasize that the Werthan Non-Entities and the Werthan Descendants have not endorsed this website nor have any affiliation with the Werthan Art Foundation. The term <span className='legaldisclaimer-higlight'> “Werthan” </span> is utilized here in a public context. The label <span className='legaldisclaimer-higlight'> “Werthan Art” </span>is derived from art pieces discovered on a property owned by Charles Jones, which purchased the Werthan Packaging Inc property containing all contents.  It is clear the Werthan Non-Entities were overseen and controlled by Werthan Descendants, however the name <span className='legaldisclaimer-higlight'> “Werthan Art Foundation” </span> is rooted in the art’s discovery location and bears no relation to the Werthan Descendants. Presently, the Werthan Descendants do not gain any financial or other benefits from the Werthan Art Foundation.
                </li>
                <li className='sublist-disclaimer'>
                    The Werthan Descendants have not endorsed this website, and they are not associated with the Werthan Art Foundation.
                </li>
            </ul>
        </div>
    )
}

export default Legaldisclaimer