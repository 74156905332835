import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PhotoSlider.css'

const PhotoSlider = ({ images }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Slider {...settings} className='slider'>
            {images.map((image, index) => (
                <div key={index} className="image-container">
                    <img src={image} alt={`Slide ${index}`} className="responsive-image" />
                </div>
            ))}
        </Slider>
    );
};

export default PhotoSlider;
