import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './Contact.css';
import emailjs from 'emailjs-com';


const Contact = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});


    const validateForm = () => {
        const newErrors = {};

        if (!firstName) {
            newErrors.firstName = 'First name is required';
        }

        if (!lastName) {
            newErrors.lastName = 'Last name is required';
        }

        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Invalid email address';
        }

        if (!message) {
            newErrors.message = 'message is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                await emailjs.sendForm('service_b22h0iq', 'template_kjc53d3', e.target, 'ZD_e4pmZjKS-B-TSX');
                console.log('Email sent successfully!');
                toast.success('Email sent successfully!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setFirstName('')
                setLastName('')
                setEmail('')
                setMessage('')
            } catch (error) {
                console.error('Error sending email:', error);
                toast.error('Error to sending email', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    };

    return (
        <div className="contact-container">
            <h2 className='contact-us-title'>Contact Us</h2>
            <p className='contact-us-describe'>If you would like to contact the Werthan Art Foundation for more information, to volunteer in support of our efforts, or to make a donation for the development of the Artifacts, please fill out the form below. You're welcome to leave comments in the available space, limited to 250 words or fewer.
            </p>
            <form onSubmit={handleSubmit} className="contact-form">
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className={errors.firstName ? 'error' : ''}
                    />
                    {errors.firstName && <p className="error-message">{errors.firstName}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className={errors.lastName ? 'error' : ''}
                    />
                    {errors.lastName && <p className="error-message">{errors.lastName}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={errors.email ? 'error' : ''}
                    />
                    {errors.email && <p className="error-message">{errors.email}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        maxLength={250}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className={errors.message ? 'error' : ''}
                    />
                    {errors.message && <p className="error-message">{errors.message}</p>}
                </div>
                <div className='contact-us-submit'>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default Contact;
