import React from 'react'
import { AiOutlineSend } from 'react-icons/ai';
import "./WerthanArtMuseum.css"

const WerthanArtMuseum = () => {
    return (
        <div className='WerthanArtMuseum-container'>
            <h2 className='WerthanArtMuseum-title'>Werthan Art Museum Mission Statement & Duties:</h2>
            <ul className='WerthanArtMuseum-describe'>
                <span className='WerthanArtMuseum-title2'><li>Mission Statement for Werthan Art Museum:</li></span>
                <AiOutlineSend /> At the Werthan Art Museum, our mission is to illuminate the rich tapestry of stories woven into the Werthan Collection. Through meticulous curation, preservation, and education, we aim to bring the past into dialogue with the present, fostering a deep appreciation for art, history, and the countless narratives embedded within. We believe in community, collaboration, and the boundless power of art to inspire, educate, and connect us all.
                <span className='WerthanArtMuseum-title2'><li>Duties:</li></span>
                <ul>
                    <span className='WerthanArtMuseum-title3'><li>Curation & Exhibition:</li></span>
                    <AiOutlineSend /> Carefully curate and display artifacts and pieces from the Werthan Collection, ensuring that each piece tells a part of the story and represents the depth and breadth of the collection.
                </ul>
                <ul>
                    <span className='WerthanArtMuseum-title3'><li>Preservation:</li></span>
                    <AiOutlineSend /> Preserve the artifacts, ensuring their longevity and maintaining the integrity of each piece for future generations.
                    <br />
                </ul>
                <ul>
                    <span className='WerthanArtMuseum-title3'><li>Education:</li></span>
                    <AiOutlineSend /> Offer educational programs, workshops, and guided tours aimed at various age groups to foster a deeper understanding and appreciation of the Werthan Collection.
                </ul>
                <ul>
                    <span className='WerthanArtMuseum-title3'><li>Research:</li></span>
                    <AiOutlineSend /> Dive deep into the stories and histories associated with the Werthan Collection, ensuring that every tale is thoroughly researched and documented.
                </ul>
                <ul>
                    <span className='WerthanArtMuseum-title3'><li>Community Engagement:</li></span>
                    <AiOutlineSend /> Host events, talks, art classes, and activities, ensuring that the community feels a connection to the collection and the stories it tells.
                    <br />
                </ul>
                <ul>
                    <span className='WerthanArtMuseum-title3'><li>Collaboration:</li></span>
                    <AiOutlineSend /> Partner with institutions like Nash Metro Archives, Tennessee State Museum, and others for joint events, research, and exhibitions.
                    <br />
                </ul>
                <ul>
                    <span className='WerthanArtMuseum-title3'><li>Digital Presence:</li></span>
                    <AiOutlineSend /> Maintain a robust online presence, offering virtual tours, digital archives, and other online resources for those who cannot visit in person.
                    <br />
                </ul>
                <ul>
                    <span className='WerthanArtMuseum-title3'><li>Conservation:</li></span>
                    <AiOutlineSend /> Invest in proper care, storage, and restoration techniques to ensure the artworks remain in prime condition.
                    <br />
                </ul>
                <ul>
                    <span className='WerthanArtMuseum-title3'><li>Publications:</li></span>
                    <AiOutlineSend /> Produce informative materials, both digital and print, that delve into the history, significance, and artistic value of the Werthan Collection.
                    <br />
                </ul>
                <ul>
                    <span className='WerthanArtMuseum-title3'><li>Inclusion:</li></span>
                    <AiOutlineSend /> Ensure representation of diverse art forms, and cultural heritages found within the Werthan Collection.
                    <br />
                </ul>
            </ul>
        </div>
    )
}

export default WerthanArtMuseum