import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className='Footer-links'>
                <a href="http://www.werthanartfoundation.org" target="_blank" style={{ textDecoration: "none" }}><p>&copy; {new Date().getFullYear()} Werthan Art Foundation</p></a>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <span className='connect-information'>Phone: 615-431-8687
                    </span><span className='legal-disclaimer-btn'><Link to="/legaldisclaimer">Legal Disclaimer</Link></span>
                </div>
            </div>
        </footer >
    );
};

export default Footer;
