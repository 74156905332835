import React from 'react'
import { AiOutlineSend } from 'react-icons/ai';
import "./MissionStatement.css"

const MissionStatement = () => {
    return (
        <div className='MissionStatement-container'>
            <h2 className='MissionStatement-title'>Werthan Art Foundation: Mission and Objectives</h2>
            <ul className='MissionStatement-describe'>
                <span className='MissionStatement-title2'><li>Werthan Art Foundation - Mission Statement</li></span>
                <AiOutlineSend /> To preserve, inspire, and educate the public through the dedicated preservation and presentation of the Werthan Art Collection, emphasizing art, education, and community involvement. This mission also recognizes and celebrates the contributions of six generations of the Werthan Family and their businesses, both locally and internationally. Our purpose extends to preserving the legacy of the Werthan art printed on the cloth bags, fostering art education, ensuring the longevity of the art for future generations, and encouraging the continual creation and restoration of artworks.

                <span className='MissionStatement-title2'><li>Core Objectives:</li></span>
                <ul className='MissionStatement-new-point'>
                    <span className='MissionStatement-title3'><li>Preservation & Display:</li></span>
                    <AiOutlineSend /> Preserve and display the artifacts unearthed at the Werthan Packaging Inc. property in Nashville, Tennessee.
                    <br />
                    <AiOutlineSend /> Ensure proper care, storage, and restoration of artworks.
                    <br />
                    <AiOutlineSend /> Operate the Werthan Art Museum & Art Gallery in Tennessee for private and public tours.
                    <br />
                    <AiOutlineSend /> Maintain a diverse representation of art forms and cultural heritages found in the Werthan Art collection.
                    <br />
                    <AiOutlineSend /> Fund public art displays and galleries featuring Werthan Art, including artifacts provided by the Werthan family.
                </ul>
                <ul className='MissionStatement-new-point'>
                    <span className='MissionStatement-title3'><li>Education & Community Engagement:</li></span>
                    <AiOutlineSend /> Collaborate with educational institutions and offer scholarships to youth art programs.
                    <br />
                    <AiOutlineSend /> Provide resources, records, and images to Nash Metro Archives, Tennessee State Museum, Jewish Federation of Nashville and Middle Tennessee, Jewish Foundation of Nashville, Gordon Jewish Community Center, and the Library of Congress (once the entire collection is processed, cataloged, and all digital images are completed).
                    <br />
                    <AiOutlineSend /> Host art classes for both children and adults, fostering community involvement in processing Werthan Artworks.
                    <br />
                    <AiOutlineSend /> Engage the community by hosting events, talks, and other activities, ensuring an inclusive space for all.
                    <br />
                    <AiOutlineSend /> Produce informative publications catering to both children and adults.
                    <br />
                    <AiOutlineSend /> Develop children’s art programs and designate funding towards public goodwill initiatives.
                </ul>
                <ul className='MissionStatement-new-point'>
                    <span className='MissionStatement-title3'><li>Digital Presence & Technology:</li></span>
                    <AiOutlineSend /> Maintain web pages for Werthan Art Museum and Foundation in both Web 2.0 and Web 3.0 environments, including the metaverse.
                    <br />
                    <AiOutlineSend /> Create a community blog for discussing the Werthan Art collection and soliciting public input.
                    <br />
                    <AiOutlineSend /> Fund and maintain servers, electronic storage, databases, and blockchain functionalities.
                </ul>
                <ul className='MissionStatement-new-point'>
                    <span className='MissionStatement-title3'><li>Licensing & Copyrights:</li></span>
                    <AiOutlineSend /> Secure copyrights and trademarks for recreated artworks.
                    <br />
                    <AiOutlineSend /> Offer licensing rights to select third parties and manage these rights in tangible and intangible forms.
                    <br />
                    <AiOutlineSend /> Advocate for the art to be featured on state specialty license plates and U.S. postage stamps (there are 29 states represented).
                    <br />
                    <AiOutlineSend /> Grant permission for copies and processed art to other non-profits under the Fair Use doctrine copyright.
                    <br />
                    Ensure quality control for licensee submissions to protect the Werthan brand and legacy, serving in a non-binding advisory role for licensee submissions.
                </ul>
                <ul className='MissionStatement-new-point'>
                    <span className='MissionStatement-title3'><li>Funding & Development:</li></span>
                    <AiOutlineSend /> Fund the purchase of artifacts to expand the Werthan Art Museum’s collection.
                    <br />
                    <AiOutlineSend /> Research historic references related to artifacts for public benefit.
                    <br />
                    <ul className='MissionStatement-new-point'>
                        <span className='MissionStatement-title3'><li className='MissionStatement-sub-list'>Income:</li></span>
                        <AiOutlineSend /> Write & receive grants,
                        <br />
                        <AiOutlineSend /> Receive donations & contributions of cash, real property and other assets,
                        <br />
                        <AiOutlineSend /> Fundraise to support the foundation's objectives
                        <br />
                        <AiOutlineSend /> Royalties from tangible and intangible sales of products.
                        <br />
                        <AiOutlineSend /> Royalties from documentary distribution/streaming fees.
                        <br />
                        <AiOutlineSend /> Museum Fees / Sales (retail)
                        <br />
                    </ul>
                    <AiOutlineSend /> Secure and fund all legal endeavors associated with copyright or trademark infringement.
                    <br />
                    <AiOutlineSend /> Fund research into companies and factories that used Werthan cloth bags.
                    <br />
                    <AiOutlineSend /> Envision future growth and design traveling art roadshows to showcase the collection across various states and cities.
                </ul>
                <ul className='MissionStatement-new-point'>
                    <span className='MissionStatement-title3'><li>Legacy & Historical Importance:</li></span>
                    <AiOutlineSend /> Emphasize the history of the Werthan Family business, their philanthropic endeavors, and the broader historical context of Werthan businesses in Tennessee and abroad.
                    <br />
                    <AiOutlineSend /> Hold licensing rights to reproduce content from "Werthan Bag: A History" by Anthony L Werthan and Jonathan Shayne.
                    <br />
                    <AiOutlineSend /> Highlight the historic significance of Werthan cloth bags, promoting their art on products, specialty license plates, and U.S. postage stamps.
                </ul>
                <ul className='MissionStatement-new-point'>
                    <span className='MissionStatement-title3'><li>Strategic Partnerships & Collaborations:</li></span>
                    <AiOutlineSend /> Collaborate to produce a documentary highlighting the Werthan family, its good deeds for the people and economy of the Nashville community and abroad, the Werthan business history, the Werthan Museum, and the story of the discovery of Werthan art and the products being made from it.
                    <br />
                    <AiOutlineSend /> Develop grant programs in states where factories using Werthan Cloth bags were located.
                    <br />
                    <AiOutlineSend /> Provide content and collaborate with non-profits to promote the Werthan Art collection.
                    <br />
                    <AiOutlineSend /> Foster partnerships for promoting Werthan art on products and ensuring brand quality.
                    <br />
                </ul>
                <span className='MissionStatement-title2'></span>
                <AiOutlineSend /> By adhering to these core objectives, the Werthan Art Foundation aims to preserve the rich legacy of the Werthan family, promote art and education, and foster community engagement.

            </ul>
        </div>
    )
}

export default MissionStatement