import React from 'react';
import Header from './components/Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Legaldisclaimer from './pages/Legaldisclaimer';
import Photospage from './pages/Photospage';
import MissionStatement from './pages/MissionStatement';
import WerthanArtMuseum from './pages/WerthanArtMuseum';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    return (
        <BrowserRouter>
            <div className="app">
                <Header />
                <ToastContainer />
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/legaldisclaimer" element={<Legaldisclaimer />} />
                    <Route path="/Photos" element={<Photospage />} />
                    <Route path="/MissionStatement" element={<MissionStatement />} />
                    <Route path="/WerthanArtMuseum" element={<WerthanArtMuseum />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
};

export default App;
