import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineSend } from 'react-icons/ai';
import './About.css';

const About = () => {
    return (
        <div className='about-container'>
            <h2 className='about-us-title'>About Us</h2>
            <ul className='about-us-describe'>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        The Werthan Art Foundation is a non-profit organization founded to offer financial and logistical assistance in handling artifacts unearthed at the Werthan Packaging Inc. property in Nashville, Tennessee. Its objectives are:
                        <ul className='about-sub-text'>
                            <li>To manually process the artifacts, crafting new art that echoes the essence of the original pieces (termed “artworks”),</li>
                            <li>To secure copyrights and trademarks for these recreated arts,</li>
                            <li>To store the renewed artworks, offering licensing rights to select third parties,</li>
                            <li>To bestow these licensing rights in both tangible and intangible forms,</li>
                            <li>To display, manage, and store the artworks for the public’s enrichment,</li>
                            <li>To preserve the history associated with these artworks,</li>
                            <li>To operate a permanent museum in Tennessee,</li>
                            <li>To design and oversee traveling art exhibits, showcasing them in various museums across states and cities, and</li>
                            <li>To produce informative publications catering to both children and adults.</li>
                            <li>To advocate for the artworks to be featured on state specialty license plates for public use,</li>
                            <li>To champion the inclusion of the artworks on United States Postage Stamps,</li>
                            <li>To establish a framework for children’s art programs and designate a portion of the revenues from licensing and specialty license plates towards public goodwill initiatives.</li>
                        </ul>
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        Our collective mission is to develop and promote the Werthan Art Collection.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        While the Werthan Art Foundation does not own any of the Artworks, it collaborates closely with third-party licensees to oversee their quality and development for various market applications.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        These include, but are not limited to, products both tangible and intangible, proposals for United States postage stamps, state-specific specialty license plates, and partnerships with Werthan family descendants, state museums, and archival entities.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        One of our key objectives is to acquire a location, whether through rent, purchase, grant, or donation, to display these Artworks in a museum setting for public enjoyment.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        For over 150 years, the Werthan Bag Company and its predecessor, the Werthan Packaging Company, have played a unique role in crafting and preserving American history.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        Operating from its factory on 8th Avenue North in Nashville, Tennessee since the late 1800s, the company transformed raw cotton into yarn, weaved it into cloth, and further processed it to create advertising images on tailor-made dry goods bags.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        These were often designed by in-house artists, numbering 25 or more at times.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        These vibrant bags were once ubiquitous in corner markets across the nation and even internationally.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        The bags were valued not just for their contents but also for their fabric quality and artistic designs.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        Customers frequently repurposed emptied bags into clothing and home goods.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        During the bag production process, molds were created to serve as plates for the images.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        The original artwork was printed on cardstock paper as "printer proofs" to guide subsequent print runs, ensuring consistency in image, style, and color.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        These proofs, along with three-dimensional molds and other artifacts, have been stored in the Werthan basement for decades and since 1999 have been under the control of the discoverer and are now owned by a third-party trust.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        These Artworks offer an unparalleled glimpse into a slice of American history, much of which has not been seen by the public in over a century.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        We are committed to bringing these images back to life, but the cost is significant.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        It exceeds $600 to process each image, not including costs for storage, maintenance, and salaries.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        Currently, the Artworks are undergoing a digitization process for various applications, products, and <span className='about-us-higlight'>licensing</span>  opportunities.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        These range from tangible items like T-shirts and posters to digital commodities like non-fungible tokens (NFTs as digital images, certificates of title and authenticity), fungible tokens and augmented reality objects, other applications and business processes which are specific to the Artworks.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        Your financial contributions or volunteered time would be greatly appreciated.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        To get involved, please fill out the <Link to="/contact"><span className='about-us-higlight'>contact form.</span></Link>
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        For <a href="http://www.werthantradingcompany.com" target="_blank"><span className='about-us-higlight'>product sales</span></a> and <span className='about-us-higlight'>blockchain applications</span> related to the Artworks, please contact the Werthan Trading Company.
                    </span>
                </li>
                <li className='about-us-list'>
                    <AiOutlineSend className='about-us-arrow' />
                    <span className='about-us-text'>
                        For <span className='about-us-higlight'>donations</span> or <span className='about-us-higlight'>contributions</span> to support the development of the collection, please contact using the form to <Link to="/contact"><span className='about-us-higlight'>contact us.</span></Link>
                    </span>
                </li>
            </ul>
        </div>
    );
};

export default About;
